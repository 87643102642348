/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { Link } from '../../store/types/admin/index';

export default {
  find() {
    return Api.get('licensing/licenses');
  },
  upload(file: File) {
    const formData = new FormData();
    formData.append('license', file);

    return Api.post('licensing/licenses/upload', formData);
  },
  activate(id: string, startTime?: string) {
    return Api.put(`licensing/licenses/${id}/activate`, {
      startTime: startTime
    });
  },
  activateMultiple(ids: string[], startTime?: string) {
    return Api.put('licensing/licenses/activate', {
      ids: ids,
      startTime: startTime
    });
  },
  download(code: string, startTime?: string, serialNumber?: string) {
    return Api.put(
      `/licensing/licenses/${code}/download`,
      {
        startTime: startTime,
        serialNumber: serialNumber
      },
      {
        responseType: 'blob'
      }
    );
  },
  downloadMultiple(codes: string[], startTime?: string, serialNumber?: string) {
    return Api.put(
      '/licensing/licenses/download',
      {
        codes: codes,
        startTime: startTime,
        serialNumber: serialNumber
      },
      {
        responseType: 'blob'
      }
    );
  },
  generate(code: string, startTime?: string) {
    return Api.put(`/licensing/licenses/${code}/generate`, {
      startTime: startTime
    });
  },
  generateMultiple(codes: string[], startTime?: string) {
    return Api.put('/licensing/licenses/generate', {
      codes: codes,
      startTime: startTime
    });
  }
};
export const getLinks = async () =>
  (await Api.get('ui/admin/links')).data as Link[];
