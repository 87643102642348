<template>
  <v-list data-cy="table-actions-button-actions-list" density="compact">
    <template v-for="action in config" :key="action.id">
      <v-list-item
        v-if="isAction(action)"
        :data-cy="'table-actions-button-' + action.title.split('.').join('-')"
        :prepend-icon="action.icon"
        @click="action.callback(item)"
      >
        {{ $t(action.title, action.translateCount ?? 1) }}
      </v-list-item>
      <v-divider v-else class="my-2" />
    </template>
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ActionsButtonWithCallback } from '../../types/DataTable';
import { ButtonCallbacks } from '@/components/types/DataTable';

export default defineComponent({
  name: 'ActionButtonList',
  props: {
    config: {
      type: Array as () => ActionsButtonWithCallback[],
      required: true
    },
    item: {
      type: Object as () => Record<string, unknown>,
      required: false,
      default: () => ({})
    }
  },
  methods: {
    isAction(action: ActionsButtonWithCallback): boolean {
      return action.id !== ButtonCallbacks.NONE;
    }
  }
});
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: 0 0.1 0 0.1 !important;
}
</style>
