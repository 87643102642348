/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { defineStore } from 'pinia';
import AccountsApi from '@/services/identity/AccountsApi';
import { i18n } from '@/plugins/i18n';
import {
  showApiErrorSnackbar,
  createErrorMessage,
  showSnackbar
} from '@/util/snackBarUtil';
import { Account, AccountMembershipType, User } from '@/store/types/identity';

interface State {
  accounts: Account[];
}
export const accountStore = defineStore('accountStore', {
  state: (): State => ({
    accounts: []
  }),

  actions: {
    async fetchAccounts() {
      try {
        this.accounts = (await AccountsApi.find()).data.members as Account[];
      } catch (error) {
        showApiErrorSnackbar(
          createErrorMessage(i18n.t('errors.fetchAccounts')),
          error
        );
      }
    },
    async toggleInternal(accountUuid: string) {
      try {
        await AccountsApi.toggleInternalAccount(accountUuid);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async updateAccount(accountToUpdate: Account) {
      try {
        await AccountsApi.update(accountToUpdate);
        showSnackbar(
          i18n
            .t('administration.editAccountSuccess', {
              msg: accountToUpdate.name
            })
            .toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async addUsersToAccount(accountUuid: string, users: User[]) {
      const payload = {
        users: []
      };
      users.forEach((user: User) => {
        let homeAccount = true;
        // if user already has a home account set home account to false
        user.accountMemberships.forEach((membership: AccountMembershipType) => {
          if (membership.isHomeAccount) {
            homeAccount = false;
          }
        });

        payload.users.push({
          userUuid: user.uuid,
          homeAccount: homeAccount
        });
      });
      try {
        await AccountsApi.linkUsersToAccount(accountUuid, payload);
        showSnackbar(i18n.t('administration.addUserSuccess', users.length));
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    }
  }
});
