<template>
  <v-select
    v-model="i18n.locale"
    variant="solo"
    density="compact"
    hide-details
    prepend-inner-icon="mdi-web"
    flat
    max-width="145px"
    class="selector"
    data-cy="language-selector"
    :items="languageOptions"
    @update:model-value="localStorage.setItem('currentLang', $event)"
  />
</template>

<script lang="ts">
import { i18n } from '@/plugins/i18n';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LanguageSelector',
  data() {
    return {
      languageOptions: [
        { title: 'English', value: 'en-GB' },
        { title: 'Deutsch', value: 'de-DE' }
      ],
      i18n,
      localStorage
    };
  }
});
</script>

<style scoped lang="scss">
.selector:deep(.v-input__control > .v-field) {
  font-size: 14px;
}
.selector:deep(.v-icon) {
  color: black;
  padding-right: 5px;
}
.selector .v-field__field .v-field__input {
  height: 30px;
}
</style>
