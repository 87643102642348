<template>
  <v-row class="title-row" no-gutters data-cy="title-counter">
    <v-col>
      <span
        :data-cy="(config.title + '-title-counter').split('.').join('-')"
        class="title-text"
        >{{ titleText }}</span
      >
    </v-col>
    <v-col v-if="!!config.titleIcon" align-self="center">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn
            :data-cy="(config.title + '-title-icon').split('.').join('-')"
            :href="config.titleIcon.link"
            :target="config.titleIcon.link ? '_blank' : undefined"
            icon
            color="secondary"
            size="medium"
            v-bind="props"
            variant="text"
            class="ml-1"
          >
            <v-icon>{{ config.titleIcon.icon }} </v-icon>
          </v-btn>
        </template>
        <span>{{ $t(config.titleIcon.tooltip) }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TableTitleCounter } from '@/components/types/DataTable';

export default defineComponent({
  name: 'TitleCounter',
  props: {
    config: {
      type: Object as () => TableTitleCounter,
      required: true
    }
  },
  computed: {
    titleText(): string {
      return this.$t(this.config.title).toString();
    }
  }
});
</script>

<style scoped lang="scss">
@use '@/styles/colors.scss';
.title-row {
  max-width: fit-content;
  white-space: nowrap;
}

.title-text {
  font-size: 20px;
  color: colors.$top-header;
}

.v-tooltip__content {
  opacity: 1 !important;
}
</style>
