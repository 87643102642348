<template>
  <v-menu
    v-model="menuExpanded"
    transition="slide-y-transition"
    location="left"
  >
    <template #activator="{ props }">
      <v-icon class="three-dot-menu" data-cy="three-dot-menu" v-bind="props">
        mdi-dots-vertical
      </v-icon>
    </template>
    <ActionButtonList :config="filteredActionsButtonConfig()" :item="item" />
  </v-menu>
</template>

<script lang="ts">
import { deviceThreeDotMenuPatch } from '@/models/inventory/deviceTableConfig';
import { merge } from 'lodash';
import { defineComponent } from 'vue';
import {
  ActionsButtonWithCallback,
  ActionTypes,
  ButtonCallbacks
} from '../../types/DataTable';
import ActionButtonList from './ActionButtonList.vue';

export default defineComponent({
  name: 'ThreeDotMenu',
  components: { ActionButtonList },

  props: {
    config: {
      type: Array as () => ActionsButtonWithCallback[],
      required: true
    },
    item: {
      type: Object as () => Record<string, unknown>,
      required: true
    }
  },
  data() {
    return {
      menuExpanded: false,
      selectionType: ActionTypes.SINGLE
    };
  },
  methods: {
    isAction(action: ActionsButtonWithCallback): boolean {
      return action.id !== ButtonCallbacks.NONE;
    },
    filteredActionsButtonConfig(): ActionsButtonWithCallback[] {
      const filteredConfig = this.config.filter((actionButton) =>
        actionButton.types.includes(this.selectionType)
      );
      filteredConfig.map((actionButton) => {
        if (actionButton.id === deviceThreeDotMenuPatch.id) {
          merge(actionButton, deviceThreeDotMenuPatch);
        }
      });
      return (
        filteredConfig.filter((action) => !!action.disabled === false) ?? []
      );
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/styles/colors.scss';

.action-list {
  background-color: colors.$table-header;
}

.three-dot-menu {
  visibility: hidden;
}

tr:hover .three-dot-menu {
  visibility: visible;
}
</style>
