<template>
  <v-menu
    v-model="showMenu"
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ props: onMenu }">
      <v-tooltip location="bottom" open-delay="100">
        <template #activator="{ props: onTooltip }">
          <v-btn
            :data-cy="(title + '-menu-button').split('.').join('-')"
            icon
            :color="iconColor"
            class="rounded pl-3"
            width="auto"
            min-width="48px"
            v-bind="{ ...onMenu, ...onTooltip }"
          >
            <v-icon>{{ icon }}</v-icon>
            <slot name="button"></slot>
            <v-icon color="white" :class="showMenu ? 'turn-180' : ''"
              >mdi-menu-down</v-icon
            >
          </v-btn>
        </template>
        <span>{{ $t(title ?? '', 2) }}</span>
      </v-tooltip>
    </template>

    <v-card flat class="text-body-2 pa-0 ma-0" max-width="300px">
      <slot name="top"></slot>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items?.filter((item) =>
            navigationItemVisible(
              item.featureFlags,
              {
                ...licensedFeatures,
                isAdminAccount: isAdminAccount
              },
              item.featureFlagAxiom
            )
          ) ?? []"
          :key="item.title"
          :data-cy="item.title.split('.').join('-')"
          :to="item.path ?? undefined"
          color="primary"
          :class="
            index === items.length - 1 ? 'top-divider app-list' : 'app-list'
          "
          @click="handleListItemClick(item)"
        >
          <template #prepend>
            <v-icon size="small" :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title class="item">
            {{ $t(item.title, 2) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  NavigationItem,
  navigationItemVisible
} from '../../../navigationDrawer/navigationDrawer';
import { FeatureFlags } from '../../../store/types/auth/index';
export default defineComponent({
  name: 'AppBarMenu',
  props: {
    items: {
      type: Array<NavigationItem>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: 'white'
    },
    licensedFeatures: {
      type: Object as () => FeatureFlags,
      required: true
    },
    isAdminAccount: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showMenu: false,
      navigationItemVisible
    };
  },
  methods: {
    handleListItemClick(item: NavigationItem) {
      if (item.callback) {
        item.callback();
      }
      this.showMenu = false;
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/styles/colors.scss' as colors;
.app-list :deep(.v-list-item__prepend) {
  width: 35px;
}
.v-card {
  border-color: colors.$light-border !important;
  border-width: 1px 2px 2px 1px !important;
  border: solid;
}
.v-btn {
  text-transform: none;
  direction: ltr !important;
}

.turn-180 {
  transform: rotate(180deg);
}
.top-divider {
  border-top: 1px solid colors.$light-border;
}
</style>
