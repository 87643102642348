import { colors } from '@/styles/colors';

export interface ValidatedActivationCode {
  activationCode: License | null;
  error: unknown | null | undefined;
  code: string;
}

export interface License extends Record<string, unknown> {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  activationCode: string;
  code: string;
  api: boolean;
  orderNumber: string;
  customerNumber: string;
  validFrom: string;
  validTo: string;
  activatedAt: string;
  expiredAt: string;
  expiresAt: string;
  duration: number;
  status: LicenseStatus;
  type: LicenseType;
  onPremises: boolean;
  licenseTier: string;
  deviceCount?: number;
}

export interface AddActivationCode {
  codes: string[];
  serialNumber?: string;
}

export enum LicenseStatus {
  LIVE = 'LIVE',
  UPLOADED = 'UPLOADED',
  EXPIRED = 'EXPIRED',
  DOWNLOADABLE = 'DOWNLOADABLE',
  POSTPONED = 'POSTPONED'
}

export enum LicenseType {
  LICENSE = 'License',
  ACTIVATION_CODE = 'ActivationCode'
}

export enum LicenseGroup {
  ALL,
  ACTIVE,
  AVAILABLE,
  EXPIRED,
  POSTPONED
}

export const LICENSE_GROUP_NAMES = {
  [LicenseGroup.ALL]: 'common.all',
  [LicenseGroup.ACTIVE]: 'main.active',
  [LicenseGroup.AVAILABLE]: 'settings.licenseStatusAvailable',
  [LicenseGroup.EXPIRED]: 'main.expired',
  [LicenseGroup.POSTPONED]: 'settings.licenseStatusPostponed'
} as const;

export const LICENSE_CHIP_COLORS = {
  [LicenseGroup.ALL]:  '',
  [LicenseGroup.ACTIVE]: colors.success,
  [LicenseGroup.AVAILABLE]: colors.primary,
  [LicenseGroup.EXPIRED]: colors.darkShadow,
  [LicenseGroup.POSTPONED]: colors.free
} as const;
