<template>
  <v-app id="app" style="max-height: 100vh">
    <ExpiredBar />
    <AppBar v-if="loggedIn" />
    <NotHomeAccountBar />
    <ConfirmUserBar v-if="notError" />
    <NavigationDrawer v-if="notError && notLogin" />
    <v-container class="pa-0 ma-0" />
    <v-main class="fill-height greyBody main-frame">
      <router-view v-slot="{ Component, route }" class="align-start">
        <transition>
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
      <CookieSnackbar />
    </v-main>
    <SnackbarContainer />
  </v-app>
</template>

<script lang="ts">
import '@/styles/global.scss';
import '@/styles/vuetify_override.scss';

import { defineComponent } from 'vue';
import NavigationDrawer from '@/components/app/navigationDrawer/NavigationDrawer.vue';
import AppBar from '@/components/app/AppBar.vue';
import CookieSnackbar from '@/components/app/CookieSnackbar.vue';
import ConfirmUserBar from '@/components/app/ConfirmUserBar.vue';
import NotHomeAccountBar from '@/components/app/NotHomeAccountBar.vue';
import ExpiredBar from '@/components/app/ExpiredBar.vue';
import SnackbarContainer from '@/components/app/SnackbarContainer.vue';
import AuthService from '@/services/auth/AuthService';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import { posthog } from 'posthog-js';

export const INACTIVE_USER_TIME_THRESHOLD = 1000 * 60 * 5;
export const USER_ACTIVITY_CONTROLLER_TIME = 1000;

interface DataType {
  isInactive: boolean;
  userActivityControllerTimeout: number;
  userActivityTimeout: number;
}

export default defineComponent({
  components: {
    CookieSnackbar,
    ExpiredBar,
    NotHomeAccountBar,
    ConfirmUserBar,
    AppBar,
    SnackbarContainer,
    NavigationDrawer
  },
  data: (): DataType => ({
    isInactive: false,
    userActivityControllerTimeout: 0,
    userActivityTimeout: 0
  }),

  computed: {
    ...mapState(authenticationStore, ['loggedIn']),
    notError() {
      const routeName = this.$route.name as string;
      return !(routeName?.includes('500') || routeName?.includes('404'));
    },
    notLogin() {
      const routeName = this.$route.name as string;
      return !routeName?.includes('login');
    }
  },

  watch: {
    $route(_: any, previous: any) {
      AuthService.checkIfAuthStateIsValid();
      posthog.capture('$pageview', {
        $referrer: window.location.origin + previous.fullPath
      });
    }
  },
  beforeMount() {
    this.activateUserActivityTracking();
    AuthService.checkIfAuthStateIsValid();
  },
  beforeUnmount() {
    this.deActivateUserActivityTracking();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityControllerTimeout);
  },
  unmounted(): void {
    // this.$destroy();
    this.$.appContext.app.unmount(); // TODO: hack; desstroy doesn't exist anymore
  },
  methods: {
    activateUserActivityTracking() {
      window.addEventListener(
        'mousemove',
        this.userActivityController.bind(this)
      );
      window.addEventListener('scroll', this.userActivityController.bind(this));
      window.addEventListener(
        'keydown',
        this.userActivityController.bind(this)
      );
      window.addEventListener('resize', this.userActivityController.bind(this));
    },
    deActivateUserActivityTracking() {
      window.removeEventListener(
        'mousemove',
        this.userActivityController.bind(this)
      );
      window.removeEventListener(
        'scroll',
        this.userActivityController.bind(this)
      );
      window.removeEventListener(
        'keydown',
        this.userActivityController.bind(this)
      );
      window.removeEventListener(
        'resize',
        this.userActivityController.bind(this)
      );
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = window.setTimeout(() => {
        this.userActivityController();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD);
    },
    userActivityController() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityControllerTimeout) {
        this.userActivityControllerTimeout = window.setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityControllerTimeout);
          this.userActivityControllerTimeout = 0;
        }, USER_ACTIVITY_CONTROLLER_TIME);
      }
    },
    inactiveUserAction() {
      this.isInactive = true;
    }
  }
});
</script>

<style lang="scss">
@use '@/styles/colors.scss' as colors;
html {
  overflow-y: hidden !important;
}
.v-menu__content {
  box-shadow: 5px 0 15px -5px colors.$dark-shadow;
}

.main-frame {
  max-height: 100%;
  overflow: auto;
}
</style>
