<template>
  <v-app-bar :color="colors.topHeader" elevation="1" density="compact">
    <router-link to="/devices">
      <v-img
        id="insys-icom-logo"
        class="mx-4"
        :src="$filters.assetUrl('assets/INSYS-icom-logo.svg')"
        height="48"
        width="95"
      />
    </router-link>
    <v-btn
      v-if="allowChangeAccount && notError"
      color="transparent"
      class="change-account-button text-none"
      data-cy="logged-in-show-change-account"
      @click="openChangeAccountDialog"
    >
      <v-icon color="white">mdi-swap-horizontal</v-icon>
      <span class="user-name-and-organisation">
        {{ $t('app.changeAccount') }}
      </span>
    </v-btn>
    <v-spacer />
    <v-img
      class="mt-1"
      :src="$filters.assetUrl('assets/icomRM-text.svg')"
      height="35"
      width="95"
    />
    <v-spacer />
    <!-- <QuickActionMenu
      v-if="loggedIn && notError"
      :get-license-features="licensedFeatures"
      :onpremises="onpremises"
      :settings="settings"
    /> -->
    <NotificationBell v-if="!onPremises" />
    <v-tooltip location="bottom" open-delay="100">
      <template #activator="{ props }">
        <v-btn
          id="menu-help-button"
          icon
          variant="text"
          color="white"
          :href="$t('common.knowledgeBaseLink')"
          target="_blank"
          class="rounded"
          v-bind="props"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('app.menuHelp') }}</span>
    </v-tooltip>
    <AppBarMenu
      v-if="notError"
      :title="userMenuWithLogoutCallback.title"
      :icon="
        showLicenseAlert ? 'mdi-account-alert-outline' : 'mdi-account-outline'
      "
      :icon-color="showLicenseAlert ? 'orange' : 'white'"
      :items="userMenuWithLogoutCallback.items"
      :licensed-features="licensedFeatures"
      :is-admin-account="isAdminAccount"
    >
      <template #top>
        <v-row justify="end" class="ma-0">
          <LanguageSelector class="pt-3 px-1" />
        </v-row>
        <v-alert
          v-if="showLicenseAlert"
          type="warning"
          :text="getAlertText"
          variant="tonal"
          class="px-3 py-2"
        />
      </template>
      <template #button>
        <span class="user-name-and-organisation">
          {{ loggedInUser.username }}
          <br />
          {{ loggedInUser?.organisation?.name ?? '' }}
        </span>
      </template>
    </AppBarMenu>
    <ChangeAccountDialog
      v-if="showChangeAccountDialog"
      :show-dialog="showChangeAccountDialog"
      @update:show-dialog="showChangeAccountDialog = false"
    />
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
import { mapActions, mapGetters } from 'vuex';
import { mapState, mapActions as mapActionsPinia } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import AppBarMenu from './appBar/AppBarMenu.vue';
import { settingsMenu, userMenu } from './appBar/menus';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import ChangeAccountDialog from '@/components/app/appBar/ChangeAccountDialog.vue';
import { dateFormatter } from '@/util/dateFormatter';
import NotificationBell from '@/components/app/appBar/Notifications/NotificationBell.vue';
import { licenseStore } from '@/store/pinia/LicenseStore';

export default defineComponent({
  name: 'AppBar',
  components: {
    AppBarMenu,
    LanguageSelector,
    ChangeAccountDialog,
    NotificationBell
  },
  data: () => ({
    colors,
    settingsMenu,
    userMenu,
    showChangeAccountDialog: false,
    dateFormatter
  }),
  computed: {
    ...mapState(authenticationStore, [
      'loggedInUser',
      'loggedInAccount',
      'allowChangeAccount',
      'licensedFeatures',
      'isAdminAccount',
      'isTrialAccount'
    ]),
    ...mapState(licenseStore, ['soonExpiringLicenses']),

    ...mapGetters({
      settings: 'systemSettings/settings',
      onPremises: 'onpremises'
    }),
    showLicenseAlert() {
      return this.onPremises ? false : this.soonExpiringLicenses[0];
    },
    getAlertText(): string {
      return this.isTrialAccount
        ? this.$t('app.trialPeriod', {
            msg: this.leftDaysTilLicenseExpiration,
            msg2: dateFormatter.dateWithoutTime(
              this.soonExpiringLicenses[0].expiresAt
            )
          })
        : this.$t(
            'app.licenseExpiresSoon',
            {
              msg2: this.soonExpiringLicenses[0].activationCode,
              msg3: this.leftDaysTilLicenseExpiration,
              msg4: dateFormatter.dateWithoutTime(
                this.soonExpiringLicenses[0].expiresAt
              )
            },
            this.soonExpiringLicenses.length
          );
    },
    userMenuWithLogoutCallback() {
      return this.userMenu(this.doLogout.bind(this));
    },
    notError(): boolean {
      const routeName = this.$route.name as string;
      return !(routeName?.includes('500') || routeName?.includes('404'));
    },
    leftDaysTilLicenseExpiration(): number {
      const expires = new Date(this.soonExpiringLicenses[0].expiresAt);
      const now = new Date();
      return Math.floor(
        (expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );
    }
  },
  created() {
    void this.loadSettings();
    void this.fetchLicenses();
  },
  methods: {
    ...mapActionsPinia(authenticationStore, ['logout']),
    ...mapActionsPinia(licenseStore, ['fetchLicenses']),
    ...mapActions({
      loadSettings: 'systemSettings/load'
    }),
    doLogout(): void {
      void this.logout();
    },
    openChangeAccountDialog(): void {
      this.showChangeAccountDialog = true;
    }
  }
});
</script>
<style lang="scss" scoped>
@use '@/styles/colors.scss' as colors;

.toolbar-title {
  color: white !important;
  margin-top: -4px;
}

.user-name-and-organisation {
  font-size: 13px;
  color: white !important;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 320px;
  text-align: left !important;
  overflow: hidden !important;
}
.change-account-button:hover {
  background-color: colors.$darker-grey !important;
}
</style>
