/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import router from './router';
import { createApp } from 'vue';
import App from './App.vue';

import { vuetify } from './plugins/vuetify';
import store from './store';
import './styles/global.scss';

import { vuei18n } from '@/plugins/i18n';
import { filters } from './filters';

// Hell yeah
import { createPinia } from 'pinia';
import { vueCookies } from '@/plugins/vue-cookies';

import { ApolloClient, HttpLink, split, InMemoryCache, ApolloLink, concat } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { TokenService } from '@/services/auth/TokenService';

import { getStaticFileContent, PosthogConfig } from './util/api/staticFiles';
import { posthog } from 'posthog-js';

const getAuthToken = () => TokenService.getAccessToken();

const httpLink = new HttpLink({
  uri: filters.getGraphqlUrlHTTP(),
  headers: {
    authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : ''
  }
});


const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    authorization: getAuthToken() ? `${getAuthToken()}` : ''
  });

  return forward(operation);
});

const authHttpLink = concat(authMiddleware, httpLink);

const wsLink = new GraphQLWsLink(
  createClient({
    url: filters.getGraphqlUrlWS(),
    connectionParams: () => ({
      authorization: getAuthToken() ? `${getAuthToken()}` : ''
    })
  })
);


// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  authHttpLink
);

// Create the apollo client
const apolloClient = new ApolloClient({
  link: link, // No need to concat authMiddleware here
  cache: new InMemoryCache()
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
});

const pinia = createPinia();

if (import.meta.env.VITE_VERSION !== 'onpremises') {
  void (async () => {
    const posthogConfig = await getStaticFileContent<PosthogConfig>('posthog.json');

    if (
      window.location.host.includes(posthogConfig.urlMatch) &&
      posthogConfig.tracking
    ){
      posthog.init('phc_7V1zEchG8t9ibOrQDVyws31G3iRz0SnhPBXj1BKF0xz', {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always',
        capture_pageview: false
      });
    }
  })();
}

export const app = createApp(App)
  .use(vuetify)
  .use(vuei18n)
  .use(router)
  .use(store)
  .use(pinia)
  .use(vueCookies)
  .use(apolloProvider);

import VueEventer from 'vue-eventer';
export const eventBus = new VueEventer();

export const applyGlobalsToComponent = (comp: typeof app) => {
  Object.entries(filters).forEach(([name, filter]) => {
    if (!comp.config.globalProperties.$filters) {
      comp.config.globalProperties.$filters = {};
    }
    comp.config.globalProperties.$filters[name] = filter;
  });
};

applyGlobalsToComponent(app);

export const appCompost = app.mount('#app');
