/* eslint-disable @typescript-eslint/naming-convention */

import de from '@/languages/german.json';
import en from '@/languages/english.json';
import { createI18n } from 'vue-i18n';

const messages = {
  'de-DE': de,
  'en-GB': en
};

// Type.define 'de-DE' as the master Schema
type MessageSchema = typeof de;

export const vuei18n = createI18n<[MessageSchema], 'en-GB' | 'de-DE'>({
  locale: localStorage.getItem('currentLang') || 'de-DE',
  fallbackLocale: 'en-GB',
  messages: messages,
  legacy: false
});

export const i18n = vuei18n.global;

export const i18nViteTest = vuei18n;
