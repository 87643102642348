import { addDays, format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const timeStrings = {
  german: {
    time: 'dd.MM.yyyy, HH:mm',
    timeWithSeconds: 'dd.MM.yyyy, HH:mm:ss'
  },
  english:{
    time: 'dd/MM/yyyy HH:mm',
    timeWithSeconds: 'dd/MM/yyyy HH:mm:ss'
  }
};

type DateValue = string | Date | undefined;

export const dateFormatter = {
  dateWithSeconds: (value: DateValue): string => {
    if (!value) {
      return '';
    }
    const date = value instanceof Date ? value : new Date(`${value}`);
    if (isNaN(date.getTime())) {
      return date.toString(); // `Invalid Date`
    }
    switch (localStorage.getItem('currentLang')) {
      case 'de-DE':
        return format(date, timeStrings.german.timeWithSeconds
        );
      default:
        return format(date, timeStrings.english.timeWithSeconds);
    }
  },
  dateWithoutSeconds: (value: DateValue): string => {
    if (!value) {
      return '';
    }
    const date = value instanceof Date ? value : new Date(`${value}`);
    if (isNaN(date.getTime())) {
      return date.toString(); // `Invalid Date`
    }
    switch (localStorage.getItem('currentLang')) {
      case 'de-DE':
        return format(date, timeStrings.german.time );
      default:
        return format(date, timeStrings.english.time);
    }
  },
  dateWithoutTime: (value: DateValue): string => {
    if (!value) {
      return '';
    }
    const date = value instanceof Date ? value : new Date(`${value}`);
    if (isNaN(date.getTime())) {
      return date.toString(); // `Invalid Date`
    }
    const language = localStorage.getItem('currentLang') ?? 'de-DE';

    return date.toLocaleDateString(
      language,
      { year: 'numeric', month: '2-digit', day: '2-digit' }
    );
  },
  dateWithoutSecondsWithoutOffset: (value: string) => {
    if (!value) {
      return '';
    }
    const timeWithoutOffset = value.split(/[+-]\d{2}:\d{2}$|Z$/)[0];
    switch (localStorage.getItem('currentLang')) {
      case 'de-DE':
        return formatInTimeZone(timeWithoutOffset, 'UTC', timeStrings.german.time );
      default:
        return formatInTimeZone(timeWithoutOffset, 'UTC', timeStrings.english.time );
    }
  },

  addDaysToDate: (value: string, days: number): string => value ? addDays(parseISO(value), days) : ''

} as const;
