import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

/**
 * Get the content of a static file from the NGINX.
 * Typically located in /app/files/.
 * @param filename - The name of the file to get the content of.
 * @returns The content of the file.
 * @example getStaticFileContent('onprem-downloads.json');
 */
export const getStaticFileContent = <T>(filename: string): Promise<T> => axios.get(`/ui/files/${filename}`, {
  baseURL: import.meta.env.VITE_BASE_URL_WITHOUT_API as string
} as AxiosRequestConfig)
  .then((response) => response.data as T)
  .catch(() => ({} as T));

// Define types of static files
export interface PosthogConfig extends Record<string, unknown> {
  urlMatch: string;
  tracking: boolean;
}

export interface Binary extends Record<string, unknown> {
  version: string;
  releasedAt: string;
  checksum: string;
  releaseNoteLink: string;
  upgradeGuideLink: string;
  installGuideLink: string;
  downloadLink: string;
}
