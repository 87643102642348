/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Account, UserFields, User } from '@/store/types';

export interface AuthStoreState {
  signupToken: string;
  authenticationError: string;
  signupError: string;
  signupSuccess: boolean;
  loggedIn: boolean;
  loggedInUser: User | Record<string, undefined>;
  loggedInAccount: Account | Record<string, undefined>;
  showNotHomeAccountBar: boolean;
  confirmUserBarLastDismissed?: number | null;
  hideReleaseBar: boolean;
  cookiesAccepted: boolean;
  temporaryUserFields: UserFields;
  licensedFeatures: FeatureFlags;
}

export interface UserLoginType {
  username: string;
  password: string;
}

export interface SignupDataType {
  emailAddress: string;
  firstName: string;
  lastName: string;
  organisationName: string;
  city: string;
  countryCode: string;
  password: string;
}

export interface AuthenticationErrorType {
  errorCode: number;
  errorMessage: string;
}

export class AuthenticationError extends Error {
  constructor(
    public errorCode: number,
    public message: string
  ) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

export class ConfirmationError extends Error {
  constructor(
    public errorCode: number,
    public message: string
  ) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

export interface FeatureFlags {
  advancedUpdateEnabled: boolean;
  apiEnabled: boolean;
  auditLogEnabled: boolean;
  autoUpdateServerEnabled: boolean;
  certificateManagementEnabled: boolean;
  cliTemplatesEnabled: boolean;
  customFieldsEnabled: boolean;
  updateJobsEnabled: boolean;
  updatePacketManagementEnabled: boolean;
  userManagementEnabled: boolean;
  isAdminAccount?: boolean;
  isHomeAccount?: boolean;
}

export interface UserInfo {
  namespace: string;
  account: string;
  organisationID: string;
  organisationRole: string[];
  username: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  preferredLanguage: string;
  scopes: string;
  roles: string;
  licenseExtensions: FeatureFlags;
  token: string;
}
