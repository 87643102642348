<template>
  <v-navigation-drawer
    v-if="loggedIn"
    rail-width="53"
    permanent
    :rail="!expandNavigationDrawer"
  >
    <v-list v-model:opened="openedMenus" open-strategy="multiple">
      <NavigationGroup
        :navigation-items="navigationDrawerConfig"
        :expand-navigation-drawer="expandNavigationDrawer"
      />
    </v-list>
    <template #append>
      <v-row no-gutters justify="end" align="end" class="white-bg mt-2">
        <div v-if="expandNavigationDrawer" class="ml-3 mb-2 pointer-cursor">
          <a
            class="label mb-2"
            :href="$t('common.knowledgeBaseLink')"
            target="_blank"
          >
            <v-icon size="small" class="mr-1">mdi-file-search-outline </v-icon
            >{{ $t('common.knowledgeBase') }}
          </a>
          <br />
          <a v-if="!onPremises" class="label mb-2" @click="goToOnPremBinaries">
            <v-icon size="small" class="mr-1"
              >mdi-archive-arrow-down-outline </v-icon
            >{{ $t('binary.selfHosted') }}
          </a>
          <br v-if="!onPremises" />
          <a class="label" @click="goToPrivacyPolicy">
            <v-icon size="small" class="mr-1">mdi-format-section </v-icon
            >{{ $t('privacy.processDataTitle') }}
          </a>
        </div>
        <v-spacer />
        <v-btn
          id="app-navigation-toggle"
          size="small"
          class="ma-0 pa-0"
          variant="text"
          icon
          @click="expandNavigationDrawer = !expandNavigationDrawer"
        >
          <v-icon>{{
            expandNavigationDrawer
              ? 'mdi-chevron-double-left'
              : 'mdi-chevron-double-right'
          }}</v-icon>
        </v-btn>
      </v-row>
    </template>
    <FeedbackDialog
      :show-dialog="showDialog"
      @update:show-dialog="showDialog = false"
    />
  </v-navigation-drawer>
</template>

<script lang="ts">
import NavigationGroup from './NavigationGroup.vue';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import {
  navigationDrawerConfig,
  defaultOpenedMenus
} from '@/navigationDrawer/navigationDrawer';
import FeedbackDialog from '@/components/app/appBar/FeedbackDialog.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

const OPEN_NAV_GROUPS = 'opened_navigation_groups';

export default defineComponent({
  components: { NavigationGroup, FeedbackDialog },

  data() {
    return {
      expandNavigationDrawer: true,
      width: '16.875em',
      showDialog: false,
      navigationDrawerConfig,
      openedMenus: [] as string[]
    };
  },
  computed: {
    ...mapState(authenticationStore, ['loggedIn']),
    ...mapGetters({ onPremises: 'onpremises' }),
    navDrawerConfig() {
      return navigationDrawerConfig;
    }
  },
  watch: {
    openedMenus(val) {
      localStorage.setItem(OPEN_NAV_GROUPS, JSON.stringify(val));
    }
  },
  beforeMount() {
    const storedNavigationState = localStorage.getItem(OPEN_NAV_GROUPS);
    if (storedNavigationState) {
      this.openedMenus = JSON.parse(storedNavigationState) as string[];
    } else {
      localStorage.setItem(OPEN_NAV_GROUPS, JSON.stringify(defaultOpenedMenus));
      this.openedMenus = defaultOpenedMenus;
    }
  },

  methods: {
    goToPrivacyPolicy() {
      void this.$router.push({ name: 'data-processing-policy' });
    },
    goToOnPremBinaries() {
      void this.$router.push({ name: 'on-premises-binaries' });
    },
    openFeedbackDialog() {
      this.showDialog = true;
    }
  }
});
</script>
<style lang="scss" scoped>
a {
  display: inline-block;
}
a.label {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
