import { i18n } from '@/plugins/i18n';
import ActivationCodeApi from '@/services/licensing/ActivationCodeApi';
import LicenseApi from '@/services/licensing/LicenseApi';
import { defineStore } from 'pinia';
import { AddActivationCode, License, LicenseStatus, LicenseType } from '../../models/licenses/licenseTypes';
import { showApiErrorSnackbar, createErrorMessage, showSnackbar } from '../../util/snackBarUtil';

export const licenseStore = defineStore('licenseStore', {
  state: () => ({
    licenses: [] as License[],
    activationCodes: [] as License[]
  }),

  getters: {
    getLicensesAndActivationCodes: (state) => {
      const licensesAndActivationCodes = state.licenses?.concat(state.activationCodes ?? [])
        .filter((activationCode) => (activationCode.status as string) !== 'EXHAUSTED');
      return licensesAndActivationCodes;

    },
    getActiveLicenses: (state) => state.licenses.filter(
      (license: License) =>
        license.status === LicenseStatus.LIVE &&
          license.type === LicenseType.LICENSE
    ).sort(
      (a, b) =>
        new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime()
    ),
    soonExpiringLicenses(): License[] {
      return this.getActiveLicenses
        .filter((license) => {
          const expires = new Date(license.expiresAt);
          const now = new Date();
          const leftDays = Math.floor(
            (expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
          );
          if (leftDays < 46) {
            return license;
          }
        });
    }
  },

  actions: {
    async fetchLicenses(errorHandling = true) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.licenses = (await LicenseApi.find()).data.members as License[];
        return this.licenses;
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
        throw error;
      }
    },
    async fetchActivationCodes(errorHandling = true) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.activationCodes = (await ActivationCodeApi.find()).data
          .members as License[];
        return this.activationCodes;
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
        throw error;
      }
    },
    async fetchLicensesAndActivationCodes(errorHandling=true) {
      try {
        await Promise.all([this.fetchLicenses(false), this.fetchActivationCodes(false)]);
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
        throw error;
      }
    },

    async addActivationCode(data: AddActivationCode, errorHandling=true) {
      try {
        await ActivationCodeApi.add(data);
        await this.fetchLicensesAndActivationCodes();
        showSnackbar(i18n.t('settings.addActivationCodeSuccess').toString());
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
        throw error;
      }
    }
  }
});
