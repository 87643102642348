import { Dialog, ButtonCallbacks } from '@/components/types/Dialog';

export const changeAccountDialog: Dialog = {
  title: 'app.changeAccount',
  dialogWidth: '85vh',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.SAVE,
      text: 'app.changeAccount'
    }]
};

export const feedbackDialog: Dialog = {
  title: 'app.feedbackTitle',
  dialogWidth: '75vh',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.SAVE,
      text: 'administration.transmit'
    }]
};

export const actionNotPossibleDialog: Dialog = {
  title: 'settings.actionNotAvailable',
  dialogWidth: '75vh',
  buttonConfig: []
};
