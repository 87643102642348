export const THREE_DOT_MENU = 'three-dot-menu';
export interface DataTableHeaderWithFilterValues extends DataTableHeader {
  filterValues?: string[];
}

export interface DataTableHeader<T extends Record<string, unknown> = Record<string, unknown>> {
  title: string;
  key?: string;
  value: keyof T;
  align?: 'start' | 'center' | 'end';
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: unknown, search: string, item: T) => boolean;
  sortRaw?: (a: T, b: T) => number;
  valueTransformer?: (value: unknown, item: T) => string | string[];
  excludedInOnPrem?: boolean;
  excludedInCloud?: boolean;
  default?: boolean;
}

export type IconButtonWithCallback = IconButton & Callback;

export type ActionsButton = ActionsButtonAction;

export type ActionsButtonWithCallback = ActionsButton & Callback;

export interface IconButton {
  id: ButtonCallbacks;
  title: string;
  icon?: string;
  activeIcon?: string;
  dataCy?: string;
}

/**
 * Set specific options for the ActionButtons found specially in the MultiSelectionDialog footer,
 * which is visible in every table that has a multi-select
 *
 * @color Give an action a certain color
 * @positionIndex Set the position of the action in the footer (0 is the first position from the left)
 * @visible Set the visibility of the action for the dialog
 */
export interface MultiSelectionDialogConfig {
  color?: string;
  positionIndex?: number;
  visible?: boolean;
}

export interface ActionsButtonAction extends IconButton {
  types: ActionTypes[];
  multiSelectionDialogConfig?: MultiSelectionDialogConfig;
}

export interface Callback {
  id: ButtonCallbacks;
  // Optional item can be passed to callback (e.g. for three dot menu); check in function if supplied or use selected elements from table
  callback: (item?) => void;
  disabled?: boolean;
  // If translations are dependent on state, put number, better enum, (from function) here
  translateCount?: number;
}

export enum ButtonCallbacks {
  ADD,
  ADD_OTHER,
  ADD_ALL,
  ADD_UPDATE_PACKET,
  ADD_CLI_TEMPLATE,
  EDIT_BULK,
  ARCHIVE,
  REFRESH,
  REMOVE,
  FILTER,
  TEST,
  RUN,
  RERUN,
  DETAILS,
  UPLOAD,
  EDIT_TABLE,
  NONE,
  DOWNLOAD,
  DOWNLOAD_LICENSE,
  LOGS,
  TOGGLE,
  WIZARD,
  SET,
  EDIT,
  ACTIVATE,
  REBUILD,
  COPY,
  RESET,
  EDIT_NAME,
  SET_VALUES,
  SET_TARGET_VALUES
}

export enum ActionTypes {
  NONE,
  SINGLE,
  MULTIPLE
}

export interface NoDataConfig {
  icon?: string;
  alertMessage: string;
  actionButtonText: string;
  callback: (() => void) | undefined;
}
export interface TableTitleCounter {
  title: string;
  titleIcon?: {
    tooltip: string;
    icon: string;
    link?: string;
  };
}
export interface Toolbar {
  titleCounterConfig: TableTitleCounter;
  iconButtonConfig: IconButtonWithCallback[];

  actionsButtonConfig?: ActionsButtonWithCallback[];
  hasFilterFunctionality?: boolean;
}

export interface TableStatusChip {
  statusString: string;
  translationKey: string;
  color: string;
}

export interface DataTable {
  tableId: string;
  hideCounter?: boolean;
  toolbarConfig?: Toolbar;
  noDataConfig?: NoDataConfig;
  statusChipConfig?: TableStatusChip[];
}
