import {
  IconButton,
  ButtonCallbacks,
  DataTable,
  TableStatusChip
} from '@/components/types/DataTable';
import { AccountLicenseTier } from '@/store/types/admin/index';
import { LICENSE_TIER_COLORS } from '@/util/accounts';
import { LICENSE_TIER_NAME } from '../../util/accounts';

export const accountTablebuttons: IconButton[] = [
  {
    id: ButtonCallbacks.EDIT,
    icon: 'mdi-pencil',
    title: 'common.edit'
  },
  {
    id: ButtonCallbacks.REFRESH,
    icon: 'mdi-refresh',
    title: 'common.refresh'
  }
];

export const accountTableConfig: DataTable = {
  tableId: 'accountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.accounts' },
    iconButtonConfig: []
  }
};
export const changeAccountTableConfig: DataTable = {
  tableId: 'changeAccountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.accounts' },
    iconButtonConfig: [],
    hasFilterFunctionality: false
  }
};

export const addUserToAccountTableConfig: DataTable = {
  tableId: 'addUserToAccountTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'administration.users' },
    iconButtonConfig: [
      {
        id: ButtonCallbacks.ADD,
        icon: 'mdi-plus',
        title: 'common.add',
        callback: () => undefined
      }
    ],
    hasFilterFunctionality: false
  }
};

export const licenseTierStatusConfig: TableStatusChip[] = [
  {
    statusString: AccountLicenseTier.UNLICENSED,
    translationKey: LICENSE_TIER_NAME[AccountLicenseTier.UNLICENSED],
    color: LICENSE_TIER_COLORS[AccountLicenseTier.UNLICENSED]
  },
  {
    statusString: AccountLicenseTier.TRIAL,
    translationKey: LICENSE_TIER_NAME[AccountLicenseTier.TRIAL],
    color: LICENSE_TIER_COLORS[AccountLicenseTier.TRIAL]
  },
  {
    statusString: AccountLicenseTier.FREE,
    translationKey: LICENSE_TIER_NAME[AccountLicenseTier.FREE],
    color: LICENSE_TIER_COLORS[AccountLicenseTier.FREE]
  },
  {
    statusString: AccountLicenseTier.BASIC,
    translationKey: LICENSE_TIER_NAME[AccountLicenseTier.BASIC],
    color: LICENSE_TIER_COLORS[AccountLicenseTier.BASIC]
  },
  {
    statusString: AccountLicenseTier.INTERNAL,
    translationKey: LICENSE_TIER_NAME[AccountLicenseTier.INTERNAL],
    color: LICENSE_TIER_COLORS[AccountLicenseTier.INTERNAL]
  }
];
