import { AccountLicenseTier } from '@/store/types/admin/index';
import { colors } from '@/styles/colors';
import { i18n } from '@/plugins/i18n';
export interface LicenseTierObject { tier: AccountLicenseTier }
export interface CheckPasswordObject { passwordConfirmed: boolean }

export const LICENSE_TIER_COLORS: { readonly [K in AccountLicenseTier]: string} = {
  [AccountLicenseTier.UNLICENSED]: colors.mediumShadow,
  [AccountLicenseTier.TRIAL]: colors.trial,
  [AccountLicenseTier.FREE]: colors.free,
  [AccountLicenseTier.BASIC]: colors.primary25,
  [AccountLicenseTier.INTERNAL]: colors.internal
};

export const LICENSE_TIER_NAME: { readonly [K in AccountLicenseTier]: string} = {
  [AccountLicenseTier.UNLICENSED]: i18n.t('administration.licenseTiers.unlicensed').toString(),
  [AccountLicenseTier.TRIAL]: i18n.t('administration.licenseTiers.trial').toString(),
  [AccountLicenseTier.FREE]: i18n.t('administration.licenseTiers.free').toString(),
  [AccountLicenseTier.BASIC]: i18n.t('administration.licenseTiers.basic').toString(),
  [AccountLicenseTier.INTERNAL]: i18n.t('administration.licenseTiers.internal').toString()
};
