<template>
  <v-card
    class="notification"
    variant="outlined"
    ma-5
    :data-cy="'notification-card-' + notification.id"
  >
    <v-card-title>
      <v-row no-gutters align="center">
        <v-icon size="24px" pb-1>
          {{ notificationIcons[notification.notificationType] }}
        </v-icon>
        <span class="notification-header">
          {{ $t('updateJobs.firmwareUpdateScheduled') }}
        </span>
        <span class="date" cols="auto">
          {{ dateFormatter.dateWithoutTime(notification.createdAt) }}
        </span>
        <v-icon
          v-if="!notification.hasBeenRead"
          size="x-small"
          class="mx-4 indicatorIsNewNotification"
          >mdi-checkbox-blank-circle</v-icon
        >
      </v-row>
    </v-card-title>
    <v-card-text>
      <p class="notification-text">{{ notificationText }}</p>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Notification } from '@/shared/notifications';
import { dateFormatter } from '@/util/dateFormatter';
export default defineComponent({
  name: 'NotificationCard',
  props: {
    notification: {
      type: Object as () => Notification,
      required: true
    }
  },
  data() {
    return {
      notificationIcons: {
        NEW_FIRMWARE_AVAILABLE: 'mdi-update'
      },
      dateFormatter
    };
  },
  computed: {
    parsedNotificationData() {
      return this.notification.data;
    },
    notificationText() {
      // This is for Backwards Compatibility, earlier notifications have Pascal Case instead of camel case
      return this.$t('updateJobs.firmwareUpdateScheduledMessage', {
        version:
          this.parsedNotificationData?.version ??
          this.parsedNotificationData?.Version,
        date: dateFormatter.dateWithoutTime(
          this.parsedNotificationData?.plannedUpdateDate ??
            this.parsedNotificationData.PlannedUpdateDate
        ),
        time: '01:00'
      });
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/styles/colors.scss' as colors;

.notification-text {
  font-size: 14px;
}
.date {
  font-size: 12px;
  color: colors.$dark-shadow;
}
.indicatorIsNewNotification {
  color: colors.$primary;
  margin-left: auto;
  margin-right: 0;
}

.notification {
  overflow: hidden;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 5px;
}

.notification-header {
  font-size: 14px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>
