/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { AddActivationCode } from '@/models/licenses/licenseTypes';

export default {
  find() {
    return Api.get('licensing/activation-codes');
  },
  add(data: AddActivationCode) {
    return Api.post('licensing/activation-codes', data);
  },
  validate(code: string) {
    return Api.post('/ui/licensing/activation-codes/validate', {
      code: code
    });
  },
  bulkValidate(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post('/ui/licensing/activation-codes/bulk-validate', formData);
  }
};
