/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  CertificateStateType,
  CertificateActionType,
  CertificateImportType,
  CertificateType
} from '@/store/types/pki';
import CertificateApi from '@/services/pki/CertificateApi';
import { certificateUploadFileTypes } from '@/models/pki/constants';
import { i18n } from '@/plugins/i18n';
import { createErrorMessage, showApiErrorSnackbar, showErrorSnackbar, showSnackbar } from '@/util/snackBarUtil';
import { downloadFile } from '@/util/downloadUtil';
import store from '../index';

export default {
  namespaced: true,

  state: {
    loading: false,
    certificates: [],
    selectedCertificates: []
  },

  getters: {
    certificates: (state: CertificateStateType) => {
      const systemSettings = store.getters['systemSettings/settings'];
      const certCaId = systemSettings.find((settings) => settings.name === 'AUTOUPDATE_CLIENT_CERT_CA_ID').value;
      return state.certificates.filter((cert) => cert.issuerUuid !== certCaId);
    },
    selectedCertificates: (state: CertificateStateType) =>
      state.selectedCertificates,
    firstSelectedCertificate(state: CertificateStateType) {
      if (state.selectedCertificates.length === 0) {
        return null;
      }
      return state.selectedCertificates[0];
    },
    showDetails: (state: CertificateStateType) =>
      state.selectedCertificates.length > 0,
    loading: (state: CertificateStateType) => state.loading,
    certificateById:
      (state: CertificateStateType) => (certificateUuid: string) =>
        state.certificates.find(
          (certificate) => certificate.uuid === certificateUuid
        ),
    certificatesSignedByIssuerUuid:
      (state: CertificateStateType) => (issuerUuid: string) =>
        state.certificates.filter((cert) => cert.issuerUuid === issuerUuid)
  },
  actions: {
    async load({ commit }: CertificateActionType) {
      commit('SET_LOADING');
      try {
        const response = await CertificateApi.find();
        commit('SET_CERTIFICATES', response.data.members);
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },
    selectCertificates: (
      { commit }: CertificateActionType,
      data: CertificateType[]
    ) => {
      commit('ADD_SELECTED_CERTIFICATES', data);
    },
    unselectCertificate: (
      { commit }: CertificateActionType,
      data: CertificateType
    ) => {
      commit('REMOVE_SELECTED_CERTIFICATE', data);
    },
    resetSelected: ({ commit }: CertificateActionType) => {
      commit('RESET_SELECTED_CERTIFICATES');
    },
    toggleSingleCertificate: (
      { commit }: CertificateActionType,
      data: CertificateType
    ) => {
      commit('TOGGLE_SINGLE_CERTIFICATE', data);
    },
    async deleteCertificate({
      dispatch,
      commit
    }: CertificateActionType, certificates: CertificateType[]) {
      const promises: Promise<any>[] = [];
      certificates.forEach((certificate) => {
        promises.push(
          CertificateApi.delete(certificate).catch((error) => {
            showApiErrorSnackbar(i18n.t('certificate.errorDeleteCert').toString(), error);
          })
        );
      });

      return Promise.all(promises).then(() => {
        dispatch('load');
        commit('RESET_SELECTED_CERTIFICATES');
      });
    },
    async updateCertificate(
      { commit }: CertificateActionType,
      data: CertificateType
    ) {
      commit('SET_LOADING', true);
      let isSuccessful = false;
      try {
        await CertificateApi.updateCertificate(data);
        isSuccessful = true;
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(i18n.t('certificate.errorChangingName')), error);
      } finally {
        // dispatch Load
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    },
    async importCertificate(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { dispatch, commit, state }: CertificateActionType,
      data: CertificateImportType
    ) {
      commit('SET_LOADING', true);
      let response;
      let isSuccessful = false;
      try {
        if (
          data.file.type === certificateUploadFileTypes.X_X509_CA_CERT ||
          data.file.type === certificateUploadFileTypes.X_PKCS12 ||
          data.file.type === certificateUploadFileTypes.PKIX_CERT
        ) {
          response = await CertificateApi.import(
            data.file,
            data.name,
            data.password
          );
        }
        if (response && response.status === 201) {
          isSuccessful = true;
          showSnackbar(`${i18n.t('certificate.uploadCertSuccessfully')}`);
        } else {
          showErrorSnackbar(`${i18n.t('certificate.uploadFailedUnknownType')}`);
        }
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        // dispatch Load
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    },
    async downloadCertificate(
      { commit }: CertificateActionType,
      certificate: CertificateType
    ) {
      commit('SET_LOADING', true);
      const isSuccessful = false;
      let response;
      try {
        response = await CertificateApi.download(certificate.uuid);
        downloadFile(response.data, certificate.name + '.p12');
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      } finally {
        commit('SET_LOADING', false);
      }
      return isSuccessful;
    }
  },
  mutations: {
    ['SET_LOADING'](state: CertificateStateType) {
      state.loading = true;
    },
    ['SET_CERTIFICATES'](
      state: CertificateStateType,
      payload: CertificateType[]
    ) {
      state.certificates = payload;
    },
    ['RESET_SELECTED_CERTIFICATES'](state: CertificateStateType) {
      state.selectedCertificates = [];
    },
    ['ADD_SELECTED_CERTIFICATES'](
      state: CertificateStateType,
      payload: CertificateType[]
    ) {
      state.selectedCertificates = payload;
    },
    ['ADD_CAS'](state: CertificateStateType, payload: CertificateType[]) {
      payload.forEach((ca: CertificateType) => {
        const index = state.certificates.findIndex(
          (existing) => existing.uuid === ca.uuid
        );
        if (index === -1) {
          state.certificates.push(ca);
        } else {
          state.certificates.splice(index, 1, ca);
        }
      });
      state.certificates = payload.sort((a, b) =>
        a.createdAt && b.createdAt
          ? a.createdAt < b.createdAt
            ? 1
            : b.createdAt < a.createdAt
              ? -1
              : 0
          : 0
      );
    },
    ['TOGGLE_SINGLE_CERTIFICATE'](
      state: CertificateStateType,
      payload: CertificateType
    ) {
      const index = state.selectedCertificates.findIndex(
        (ca: CertificateType) => ca.uuid === payload.uuid
      );
      if (index === -1) {
        state.selectedCertificates = [];
        state.selectedCertificates.push(payload);
      } else {
        state.selectedCertificates.splice(index, 1);
      }
    }
  }
};
